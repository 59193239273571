import React from "react"
import theme from "theme"
import {
  Theme,
  Text,
  Link,
  Box,
  Section,
  Icon,
  Strong,
  Image,
  LinkBox,
} from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
import { IoIosCheckmarkCircle } from "react-icons/io"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Ana Sayfa | Wera Auto Service'a Hoş Geldiniz</title>
        <meta name={"description"} content={"Her Sürüşün Önemli Olduğu Yer"} />
        <meta
          property={"og:title"}
          content={"Ana Sayfa | Wera Auto Service'a Hoş Geldiniz"}
        />
        <meta
          property={"og:description"}
          content={"Her Sürüşün Önemli Olduğu Yer"}
        />
        <meta
          property={"og:image"}
          content={"https://xovilo.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://xovilo.com/img/2547477.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://xovilo.com/img/2547477.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://xovilo.com/img/2547477.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://xovilo.com/img/2547477.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://xovilo.com/img/2547477.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://xovilo.com/img/2547477.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="linear-gradient(0deg,rgba(255, 255, 255, 0.6) 0%,rgba(255, 255, 255, 0.6) 100%),url(https://xovilo.com/img/1.jpg) center center/cover no-repeat"
        min-height="100vh"
        lg-padding="140px 0 88px 0"
        quarkly-title="Hero-21"
        padding="88px 0 88px 0"
      >
        <Override
          slot="SectionContent"
          flex-direction="column"
          flex-wrap="wrap"
          align-items="center"
          justify-content="center"
        />
        <Text
          letter-spacing="2px"
          margin="0px 0px 16px 0px"
          color="--greyD2"
          font="--base"
          lg-width="100%"
          lg-text-align="center"
          text-align="center"
          text-transform="uppercase"
        >
          Sürüşünüz Burada Başlıyor
        </Text>
        <Text
          color="--darkL1"
          font="--headline1"
          sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          md-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          margin="0px 0px 42px 0px"
          lg-width="100%"
          md-width="100%"
          lg-text-align="center"
          lg-margin="0px 0px 20px 0px"
          lg-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          text-align="center"
        >
          Wera Auto Service
        </Text>
        <Text
          lg-text-align="center"
          text-align="center"
          margin="0px 0px 36px 0px"
          color="--darkL2"
          font="--lead"
          lg-width="100%"
        >
          Olağanüstü araç servisi ve onarımı için nihai hedefiniz olan Wera Auto
          Service'a hoş geldiniz. Wera Auto Service'da, her sürüşün sadece bir
          işe gidip gelme değil, bir yolculuk olması gerektiğine inanıyoruz.
          Mükemmelliğe ve kaliteye olan bağlılığımız, aracınızın mümkün olan en
          iyi bakımı almasını sağlayarak her yolculuğu sorunsuz bir deneyim
          haline getirir.
        </Text>
        <Box display="flex" sm-flex-direction="column">
          <Link
            color="--light"
            font="--lead"
            sm-margin="0px 0px 20px 0px"
            sm-width="100%"
            hover-color="--dark"
            border-width="2px"
            border-style="solid"
            href="/contacts"
            text-decoration-line="initial"
            border-radius="75px"
            background="--color-dark"
            border-color="--color-dark"
            padding="10px 53px 10px 53px"
            margin="0px 24px 0px 0px"
            transition="background-color 0.3s linear 0s"
            hover-background="rgba(4, 8, 12, 0)"
            sm-text-align="center"
          >
            Bize Ulaşın
          </Link>
        </Box>
      </Section>
      <Section
        padding="90px 0 90px 0"
        sm-padding="40px 0"
        min-height="600px"
        sm-min-height="auto"
        display="flex"
        lg-padding="45px 0 45px 0"
        quarkly-title="Advantages/Features-18"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          grid-gap="5%"
          max-width="1200px"
          sm-min-width="280px"
        />
        <Box
          display="flex"
          width="100%"
          flex-direction="column"
          align-items="center"
          lg-width="100%"
          sm-margin="0px 0px 40px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-flex-direction="row"
          lg-flex-wrap="wrap"
          lg-justify-content="center"
          sm-justify-content="flex-start"
        >
          <Text
            as="h2"
            margin="4px 0px 12px 0px"
            font="--headline2"
            color="--darkL2"
            width="100%"
            md-width="100%"
            text-align="center"
            sm-text-align="left"
          >
            Hakkımızda
          </Text>
          <Text
            margin="8px 0px 20px 0px"
            font="--lead"
            text-align="center"
            color="--darkL2"
            width="70%"
            lg-width="100%"
            sm-text-align="left"
          >
            Ekibimiz, Gururumuz Wera Auto Service'ın kalbinde yetenekli
            teknisyenlerden oluşan ekibimiz yer alır. Her bir üyemiz sadece bir
            teknisyen değil, mekaniğin ötesinde her aracın ruhunu gören bir
            otomobil tutkunudur. Teknisyenlerimiz mükemmellik taahhüdümüzün
            temel taşıdır ve otomotiv yeniliklerinin bir adım önünde olmak için
            becerilerini ve bilgilerini sürekli olarak güncellerler. Kendilerini
            adamaları, Wera Auto Service'dan çıkan her aracın sadece servis
            görmesini değil, aynı zamanda yeniden canlanmasını sağlar.
          </Text>
          <Text
            as="h2"
            margin="40px 0px 0px 0px"
            font="--headline2"
            color="--darkL2"
            width="100%"
            md-width="100%"
            text-align="center"
            sm-text-align="left"
          >
            Bizi Diğerlerinden Ayıran
          </Text>
          <Box
            display="grid"
            width="100%"
            grid-template-columns="repeat(2, 1fr)"
            grid-gap="24px"
            margin="60px 0px 0px 0px"
            max-width="1000px"
            md-grid-template-columns="1fr"
            md-grid-gap="6px"
            sm-grid-gap="32px"
          >
            <Box
              padding="24px 24px 48px 24px"
              border-radius="8px"
              display="flex"
              sm-padding="0px 0px 0px 0px"
              md-padding="24px 24px 24px 24px"
              sm-flex-direction="column"
            >
              <Icon
                category="io"
                icon={IoIosCheckmarkCircle}
                size="42px"
                color="--light"
                margin="0px 24px 0px 0px"
                background="--color-primary"
                padding="8px 8px 8px 8px"
                border-radius="8px"
                sm-margin="0px 0px 12px 0px"
              />
              <Box>
                <Text
                  font="--lead"
                  color="--darkL2"
                  margin="0px 0px 0px 0px"
                  letter-spacing="0.3px"
                  sm-margin="4px 0px 0px 0px"
                >
                  <Strong>Hassasiyet ve Uzmanlık</Strong>
                </Text>
                <Text
                  margin="16px 0px 0px 0px"
                  font="--base"
                  color="--darkL1"
                  sm-margin="6px 0px 0px 0px"
                >
                  Uzman ekibimiz her göreve yılların deneyimini katarak,
                  çevirdikleri her cıvatada ve taktıkları her parçada hassasiyet
                  sağlar.
                </Text>
              </Box>
            </Box>
            <Box
              padding="24px 24px 48px 24px"
              border-radius="8px"
              display="flex"
              sm-padding="0px 0px 0px 0px"
              md-padding="24px 24px 24px 24px"
              sm-flex-direction="column"
            >
              <Icon
                category="io"
                icon={IoIosCheckmarkCircle}
                size="42px"
                color="--light"
                margin="0px 24px 0px 0px"
                background="--color-primary"
                padding="8px 8px 8px 8px"
                border-radius="8px"
                sm-margin="0px 0px 12px 0px"
              />
              <Box>
                <Text
                  font="--lead"
                  color="--darkL2"
                  margin="0px 0px 0px 0px"
                  letter-spacing="0.3px"
                  sm-margin="4px 0px 0px 0px"
                >
                  <Strong>Müşteri Odaklı Yaklaşım</Strong>
                </Text>
                <Text
                  margin="16px 0px 0px 0px"
                  font="--base"
                  color="--darkL1"
                  sm-margin="6px 0px 0px 0px"
                >
                  Sizin memnuniyetiniz bizi yönlendiriyor. Kendimizi, aracınızın
                  benzersiz ihtiyaçlarını karşılayan kişiselleştirilmiş
                  hizmetler sunmaya adadık.
                </Text>
              </Box>
            </Box>
            <Box
              padding="24px 24px 48px 24px"
              border-radius="8px"
              display="flex"
              sm-padding="0px 0px 0px 0px"
              md-padding="24px 24px 24px 24px"
              sm-flex-direction="column"
            >
              <Icon
                category="io"
                icon={IoIosCheckmarkCircle}
                size="42px"
                color="--light"
                margin="0px 24px 0px 0px"
                background="--color-primary"
                padding="8px 8px 8px 8px"
                border-radius="8px"
                sm-margin="0px 0px 12px 0px"
              />
              <Box>
                <Text
                  font="--lead"
                  color="--darkL2"
                  margin="0px 0px 0px 0px"
                  letter-spacing="0.3px"
                  sm-margin="4px 0px 0px 0px"
                >
                  <Strong>Şeffaf Fiyatlandırma</Strong>
                </Text>
                <Text
                  margin="16px 0px 0px 0px"
                  font="--base"
                  color="--darkL1"
                  sm-margin="6px 0px 0px 0px"
                >
                  Sürpriz yok, gizli ücret yok. İlgili maliyetler hakkında
                  dürüst ve açık iletişime inanıyoruz.
                </Text>
              </Box>
            </Box>
            <Box
              padding="24px 24px 48px 24px"
              border-radius="8px"
              display="flex"
              sm-padding="0px 0px 0px 0px"
              md-padding="24px 24px 24px 24px"
              sm-flex-direction="column"
            >
              <Icon
                category="io"
                icon={IoIosCheckmarkCircle}
                size="42px"
                color="--light"
                margin="0px 24px 0px 0px"
                background="--color-primary"
                padding="8px 8px 8px 8px"
                border-radius="8px"
                sm-margin="0px 0px 12px 0px"
              />
              <Box>
                <Text
                  font="--lead"
                  color="--darkL2"
                  margin="0px 0px 0px 0px"
                  sm-margin="4px 0px 0px 0px"
                >
                  <Strong>Verimlilik ve Güvenilirlik</Strong>
                </Text>
                <Text
                  margin="16px 0px 0px 0px"
                  font="--base"
                  color="--darkL1"
                  sm-margin="6px 0px 0px 0px"
                >
                  Zamanınıza saygı duyuyoruz. Ekibimiz, aracınızı mümkün
                  olduğunca hızlı ve güvenli bir şekilde yola geri döndürmek
                  için özenle çalışır.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="80px 0 80px 0"
        lg-padding="60px 0 60px 0"
        md-padding="40px 0 40px 0"
        sm-padding="30px 0 30px 0"
      >
        <Box
          margin="0px 0px 32px 0px"
          display="flex"
          flex-direction="column"
          align-items="flex-start"
        >
          <Text
            font="--headline2"
            color="--dark"
            text-align="center"
            md-font='normal 700 30px/1.2 "Source Sans Pro", sans-serif'
            margin="16px 0px 0px 0px"
          >
            Aracınızın Yolculuğu Bizimle Devam
          </Text>
        </Box>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="30%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://xovilo.com/img/2.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="356px"
              width="100%"
              object-fit="none"
            />
          </Box>
          <Box
            width="70%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
            >
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                Ediyor Aracınız hayat yolculuğunuzun bir uzantısıdır. Wera Auto
                Service'da biz bir servis sağlayıcıdan daha fazlasıyız - bu
                yolculuğun sorunsuz, güvenli ve keyifli olmasını sağlamak için
                ortağınızız. Eşsiz özen ve uzmanlıkla sürüş deneyiminizi
                geliştirerek hikayenizin bir parçası olmamıza izin verin.
              </Text>
            </Box>
          </Box>
        </LinkBox>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
